import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Itstaffingtopbanner from './Itstaffingtopbanner'
import Itstaffingfirstsection from './Itstaffingfirstsection'
import Itstaffingimagesection from './Itstaffingimagesection'
import Footer from '../../../common components/Footer'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

const Itstaffingwholepage = () => {
  return (
    <>
      <Helmet>
        <title>Top IT Staffing and Support Services | Praathee Technologies</title>
        <meta name="description" content="Transform your IT landscape with premium staffing and support solutions. Elevate your business with our specialized services." />
        <meta property="og:title" content="Expert IT Staffing and Support Services for Your Business" />
        <meta property="og:description" content="Discover reliable IT staffing and support solutions. Your trusted partner for seamless tech integration." />
        <meta name="keywords" content="it staffing, support, service, company, solution, india, coimbatore, agency, it recruitment, it employment" />
        <link rel="canonical" href="https://www.praathee.com/software-maintenance-service" />
      </Helmet>
      <Topnavbar />
      <Itstaffingtopbanner />
      <Container>
        <div className='my-5'>
          <Itstaffingfirstsection />
        </div>
        <div className='mb-5'>
          <Itstaffingimagesection />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Itstaffingwholepage



