import React from 'react'
import faqhome2 from "../assets/img/faq_home_2.jpg"
import whycooseus from "../assets/img/new_about_faq.png"
import { Accordion, Card } from 'react-bootstrap';


const Whychooseus = () => {
  return (
    <div className='body-wrapper container'>
      <section class="faq-section section-padding">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-6">
              <div className='about_page_faq_sec_img_div'>
                <img src={whycooseus} alt='faq' className='about_page_faq_img' />
              </div>
            </div>
            <div class="col-xl-5 col-lg-6">
              <div class="col-12 col-lg-12 mb-40">
                <div class="section-title">
                  <h3 className='why-choose'>Why choose us</h3>
                  <h1>Commitment  to Quality </h1>
                </div>
              </div>

              <div class="faq-content">
                <div class="faq-accordion">
                  <div id="accordion" class="accordion">

                    <Accordion >
                      <Accordion.Item eventKey="0" className='card'>
                        <Accordion.Header class="collapsed mb-0 text-capitalize">How best does your company help improve our IT infrastructure?</Accordion.Header>
                        <Accordion.Body className='card-body'>
                          As a leading software development company, we provide specialized services in all areas of expertise. From optimizing better performance to delivering products in the said time, everything is prioritized. Besides that, our service focuses on every field. I.e., from startups to large enterprises, we provide expert solutions with excellence and professionalism. So, undoubtedly you can choose from our services and succeed.                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1" className='card'>
                        <Accordion.Header class="collapsed mb-0 text-capitalize">List the security measures that you provide to your clients?</Accordion.Header>
                        <Accordion.Body className='card-body'>
                          We give high priority to our client’s security. As every detail is crucial, we follow a strict privacy policy through this list of methods.
                          Advanced encrypted methods
                          <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                            <li>Robust Firewall Protection</li>
                            <li>Extensive Security Audits</li>
                            <li>Comprehensive Employee Training</li>
                            <li>Multi-layered Approach</li>
                          </ul>

                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2" className='card'>
                        <Accordion.Header class="collapsed mb-0 text-capitalize">How do you calculate your pricing structure for design and development services?</Accordion.Header>
                        <Accordion.Body className='card-body'>
                          Our pricing structure varies with the design and services that you choose. While the pricing structure might differ they are cost-friendly and packed within your budget. Besides that, here you don’t need to pay anything extra. Pricing standards are simple and of the range.                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3" className='card'>
                        <Accordion.Header class="collapsed mb-0 text-capitalize"> What is your approach to project management?</Accordion.Header>
                        <Accordion.Body className='card-body'>
                          We adopt a collaborative approach to project management, where we work closely with our clients throughout the project lifecycle. We begin by thoroughly understanding your requirements and objectives, then create a detailed project plan with clear milestones and deliverables. Our team maintains open communication channels, provides regular updates, and seeks feedback to ensure that the project stays on track and meets your expectations.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Whychooseus