import React from 'react'
import Topnavbar from "../common components/Topnavbar";
import Footer from "../common components/Footer";

import Aboutcompanyaboutsection from "./Aboutcompanyaboutsection";
import Whychooseus from "./Whychooseus";
import Exclusivemembers from "./Exclusivemembers";
import Counts from "./Counts";
import Popularitservices from "./Popularitservices";
import Aboutsectiontestimonial from "./Aboutsectiontestimonial";
import Listofclientsaboutsection from "./Listofclientsaboutsection";
import Aboutsectiontopbanner from "./Aboutsectiontopbanner";
import { Helmet } from 'react-helmet';

const Aboutwholepage = () => {

  return (
    <div>
      <Helmet>
        <title>: Praathee Technologies : Crafting Excellence in Software Solutions</title>
        <meta name="description" content="Discover Praathee Technologies, a leader in software solutions, renowned for our commitment to excellence and innovation. Trust us to elevate your business to new heights." />
        <meta property="og:title" content="Praathee Technologies: Delivering Excellence in Software Solutions" />
        <meta property="og:description" content="Choose Praathee Technologies for unparalleled excellence in software solutions. With our proven track record, we're committed to delivering results that exceed expectations." />
        <meta name="keywords" content="about praathee, Praathee Technologies, software developmet, mobile, web,  app development , india, coimbatore, blockchain, devlopment, cloud app, software, it, company," />
      </Helmet>

      <Topnavbar />
      <Aboutsectiontopbanner />
      <Aboutcompanyaboutsection />
      <Whychooseus />
      <Exclusivemembers />
      {/* <Counts/> */}
      {/* <Popularitservices/> */}
      <Aboutsectiontestimonial />
      <Listofclientsaboutsection />
      <Footer />
    </div>
  )
}

export default Aboutwholepage







