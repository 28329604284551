import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.webp"
import { Row,Col} from 'react-bootstrap'

const Customimagesection = () => {
  return (
    <Row className="m-5">
    <Row >
        
        <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="100">
                    <a  className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            01
                        </div> */}
                        <div className="content"> 
                            <h3>Seamless Integration</h3>
                            <hr/>
                            <p>We excel in API integration,possessing knowledge of various third-party software that can be utilized for betterment of your Organization</p>
                        </div>
                    </a>
                </div>
       
      
        <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="100">
                    <a  className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            02
                        </div> */}
                        <div className="content"> 
                            <h3>User Experience (UX)/User Interface (UI) Design</h3>
                            <hr/>
                            <p>We craft sleek and intuitive interfaces to ensure optimal conversion rates.</p>
                        </div>
                    </a>
                </div>
     
        
                <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="100">
                    <a  className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            03
                        </div> */}
                        <div className="content"> 
                            <h3>Quality Analysis & Testing</h3>
                            <hr/>
                            <p>Our QA Engineers conduct functional, performance, and security testing, as well as UX/Product checks and others.</p>
                        </div>
                    </a>
                </div>
                
        
    </Row>
    <Row>
    
    
        <div className="col-12 col-md-6 col-xl-3" data-aos="fade-up" data-aos-delay="100">
                    <a  className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})`}}>
                        {/* <div className="number">
                            05
                        </div> */}
                        <div className="content"> 
                        <hr/>
                            <h3>Web application maintenance and support</h3>
                            <p>Build a well-constructed website and web application  functionality & interactivity using our services</p>
                        </div>
                    </a>
                </div>
     
       
       
      
        
    </Row>

</Row>
  )
}

export default Customimagesection