import React from 'react'
import aboutus from "../assets/img/home1/about-us.jpg"
// import expertconsultant from "../assets/img/new_about.jpg"
import expertconsultant from "../assets/img/tscfo_81.png"
import { FaUserGroup } from "react-icons/fa6";
import { TbTargetArrow } from "react-icons/tb";


const Aboutcompanyaboutsection = () => {
    return (
        <div className='body-wrapper'>
            <section class="about-us-wrapper section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-12 pr-5">
                            <div class="section-title mb-30">
                                <p>About Company</p>
                                <h1>Get’s IT Solutions For <br />
                                    Expert Consultants</h1>
                            </div>

                            <p class="pr-lg-5">For years, Praathee Technologies has been serving its clients with the best software solutions and is delivering top-notch products efficiently. From small businesses to leading software companies, everyone is getting their desired services and is gaining popularity seamlessly.

                                <br />

                                Our company is the best because our expert team is skilled in offering simple yet unique solutions. As a solution provider, we understand the competition and tools that are constantly evolving. So, accordingly, we stay updated and serve only the finest of anything that you ask for.
                            </p>

                            <div class="about-icon-box style-2">
                                <div class="icon">
                                    <FaUserGroup />
                                </div>
                                <div class="content">
                                    <h3>Professional Consultants</h3>
                                    <p>Your success is our priority. With the best-picked talents in our professional spectrum, your work will be smooth and 100% reliable. From the start to the end, the software development services that you need will be researched and delivered with the utmost professionalism. No hassles will be found here. </p>
                                </div>
                            </div>
                            <div class="about-icon-box style-2">
                                <div class="icon">
                                    <TbTargetArrow />
                                </div>
                                <div class="content">
                                    <h3>IT Services & Managements</h3>
                                    <p>Innovate, transform, and revolutionize your business goals and IT developments with our professional and versatile IT Management services effortlessly.</p>
                                </div>
                            </div>

                        </div>

                        <div class="col-lg-6 pl-lg-5 mt-5 mt-lg-0 col-12">
                            <div class="about-right-img">
                                <span class="dot-circle"></span>
                                <div className="about-us-img" style={{ backgroundImage: `url(${expertconsultant})` }} alt="Get’s IT Solutions For
Expert Consultants">
                                </div>
                                <span class="triangle-bottom-right"></span>
                            </div>
                        </div>
                    </div>

                    <div class="row mpt-50 pt-30">
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="icon">
                                    <i class="flaticon-speech-bubble"></i>
                                </div>
                                <div class="content">
                                    <h3>Faster <br />Development </h3>
                                    <p>Delivering products or software solutions with precision and flexibility is our goal. The more committed we are, the better the results are. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="icon">
                                    <i class="flaticon-unlock"></i>
                                </div>
                                <div class="content">
                                    <h3>24x7 Customer Support</h3>
                                    <p>We stay open to our client’s needs and provide support with undivided attention 24/7.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-12">
                            <div class="single-features-item">
                                <div class="icon">
                                    <i class="flaticon-mobile-app"></i>
                                </div>
                                <div class="content">
                                    <h3>Support & Maintenance</h3>
                                    <p>We are equipped with best-class equipment and provide exceptional software development services with promising prominence.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Aboutcompanyaboutsection