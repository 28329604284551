import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import brand1 from "../assets/img/LogNCM12.png";
import brand2 from "../assets/img/EonianLogo.webp";
import brand3 from "../assets/img/yf.png";
import brand4 from "../assets/img/reliance_logo.jpg";
import brand5 from "../assets/img/n-m-engineering-industry-logo.jpg";
import brand6 from "../assets/img/ans.jpeg";

const Listofclients = () => {
    var settings = {
      dots:true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, 
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 1000,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          }
        ]
    };
    
    return (
        <div className='body-wrapper'>  
            <div className="client-brand-logo-wrap techex-landing-page pt-0">
                <div>
                    <Slider {...settings}>
                        <div className="single-client">
                            <img src={brand1} alt="NetCreativeMind Solutions"/>
                        </div>
                        <div className="single-client">
                            <img src={brand2} alt="Eonian Software Solutions"/>
                        </div>
                        <div className="single-client">
                            <img src={brand3} alt="YourFarm AgriTech"/>
                        </div>
                        <div className="single-client">
                            <img src={brand4} alt="Reliance Industries Limited"/>
                        </div>
                 
                        <div className="single-client">
                            <img src={brand6} alt="ans solution"/>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Listofclients;
