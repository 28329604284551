import React from 'react';
import ourcompanyimg from '../../src/assets/img/New_home_img/Rectangle 7.png'

const CompanyIntroduction = () => {
  return (
    <div className="company-introduction ">
      <div className="content-wrapper">
        <div className="text-container">
        <div className="company-heading">
  A Proficient Software <br /> Development Company
</div>

          <div className='company_sub_heading'>
            Praathee Technologies marks its milestone as one of the most searched top software development companies.
          </div>
           <div className="custom-features">
            <ul>
              <li className="custom-feature-item">With 7+ years of experience, we excel in software solution expertise.</li>
              <li className="custom-feature-item">We provide various services in a timely and budget-friendly manner</li>
              <li className="custom-feature-item">As the best software development company, we offer versatile and seamless services.</li>
              <li className="custom-feature-item">If you are a startup or business looking for remarkable software development services, connect with us and achieve results immediately.</li>
            </ul>
          </div>
        </div>
        <div className="image-container">
          <img src={ourcompanyimg} alt="Company" />
        </div>
      </div>
    </div>
  );
};

export default CompanyIntroduction;
