import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Blockchaintopbanner from './Blockchaintopbanner'
import Blockchainfirstsection from './Blockchainfirstsection'
import Footer from '../../../common components/Footer'
import Blockchainimagesection from './Blockchainimagesection'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

const Blockchainwholepage = () => {
  return (
    <>
      <Helmet>
        <title>Best Blockchain and Smart Contract Development Services</title>
        <meta name="description" content="Experience the future of technology with Praathee's expert blockchain development services. Redefine processes and enhance security with our blockchain expertise." />
        <meta property="og:title" content="Top Blockchain Development Company : Expert Solutions" />
        <meta property="og:description" content="Join the blockchain revolution with Praathee Technologies. As industry leaders, we harness blockchain's power to drive innovation and transformation for businesses worldwide." />
        <meta name="keywords" content="blockchain development, company, service, blockchain app developer, smart contract development" />
        <link rel="canonical" href="https://www.praathee.com/blockchain-development-service" />
      </Helmet>
      <Topnavbar />
      <Blockchaintopbanner />
      <Container>
        <div className='my-5'>
          <Blockchainfirstsection />
        </div>
        <div className='mb-5'>
          <Blockchainimagesection />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Blockchainwholepage


