import React from "react";
import devloperimg from "../../src/assets/img/New_home_img/Software-Dev.png"

const SoftwareDevelopmentInfo = () => {
  return (
    <div className="custom-software-development-info custom-container">
      <div className="custom-row">
        <div className="custom-col-6 custom-image-container">
          <img
            src={devloperimg}
            alt="Innovation"
          />
        </div>
        <div className="custom-col-6 custom-content">
          <h2 className="custom-heading">
            What Makes Our Software Development Company The Best Service Provider?
          </h2>
          <div className="custom-quote">
            <strong>
              “Understanding and Innovation together can elevate software solutions faster.”
            </strong>
          </div>
          <p className="content p">
            With exceptional ideas, we always strive to break down complex problems into a simple solution.
            So, whatever your needs are, you can reach us and leave the rest assured.
          </p>
          <div className="custom-features">
            <ul>
              <li className="custom-feature-item">Professionalism with Excellent Guidance</li>
              <li className="custom-feature-item">Custom-Made Designs</li>
              <li className="custom-feature-item">Consistent Software Solutions</li>
              <li className="custom-feature-item">100% Quality Assurance</li>
              <li className="custom-feature-item">On-Time Delivery</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentInfo;
