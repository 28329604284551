import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Topnavbar from '../common components/Topnavbar';
import FooterForCareer from '../common components/FooterforCareer';
import { Row, Col } from 'react-bootstrap';
import careerpageimage1 from "../assets/img/new_career.webp"
import { Helmet } from 'react-helmet';


export const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Join Praathee Technologies: Explore Exciting Career Opportunities</title>
        <meta name="description" content="Explore rewarding career opportunities at Praathee Technologies, a leading software solution provider. Join our dynamic team and unleash your potential." />
        <meta property="og:title" content="Shape Your Future with Praathee Technologies: Explore Job Openings" />
        <meta property="og:description" content="Join Praathee Technologies and embark on a journey of growth and success. Explore our diverse career opportunities and find your perfect fit." />
      </Helmet>

      <Topnavbar />
      <div className='container'>
        <Row className='my-5'>
          <Col xs={12} md={5} sm={12} lg={5}>
            <p style={{ textAlign: 'justify' }}>Praathee helps you grow beyond set designations and pre-defined roles to realize your true potential.

              We are always on the lookout of professionals with sound IT knowledge, passion and urge to think out of the box. We are also keen on putting in place a multi-disciplinary team. So if you are a go-getter in your domain and are keen to take up new challenges, please mark your resume to                             <p><a href="mailto:hr@praathee.com" className='text-decoration-none'> &nbsp;hr@praathee.com</a></p>
              Our HR team will get in touch with you if your skill set matches our present-day requirements.</p>
          </Col>
          <Col xs={12} md={6} sm={12} lg={6} style={{ margin: '0 auto', textAlign: 'center', maxWidth: '100%' }}>
            <img src={careerpageimage1} alt='careerpageimage1' className='custom-image' style={{ width: '100%', maxWidth: '100%', height: 'auto' }} />
          </Col>

        </Row>
        <div className='container'>
          <div className="row section-padding pb-0 ">
            <div className="col-12 text-center mb-20">
              <div className="section-title">
                <h1>Shape your future with us as we collaborate to make a meaningful impact</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterForCareer />


    </>
  );
};
