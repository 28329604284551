import React from 'react';
import { Helmet } from 'react-helmet';
import Topnavbar from '../../../common components/Topnavbar';
import Footer from '../../../common components/Footer';
import Mobiletopbanner from './Mobiletopbanner';
import Mobilefirstsection from './Mobilefirstsection';
import Mobileimagesection from './Mobileimagesection';
import { Container } from 'react-bootstrap';

const Mobilewholepage = () => {
  return (
    <>
      <Helmet>
        <title>Top Mobile App Development Service |  Build Your Dream App</title>
        <meta name="description" content="Boost your business with Praathee's mobile app development. Specializing in custom mobile solutions, we deliver apps that are innovative, user-friendly, and goal-aligned." />
        <meta property="og:title" content="Transforming Ideas Into Apps with Our Mobile App Developers" />
        <meta property="og:description" content="Praathee Technologies is the leading mobile app development company. From ideation to launch, our experts craft custom, cutting-edge apps tailored to your needs" />
        <meta name="keywords" content="mobile app development, android app development, ios app development , hybrid app development , app developers, app development, mobile app development, mobile application development" />
      </Helmet>
      <Topnavbar />
      <Mobiletopbanner />
      <Container>
        <div className='my-5'>
          <Mobilefirstsection />
        </div>
        <div className='mb-5'>
          <Mobileimagesection />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Mobilewholepage;
