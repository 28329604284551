import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Maintenancetopbanner from './Maintenancetopbanner'
import Maintenanceimagesection from './Maintenanceimagesection'
import Footer from '../../../common components/Footer'
import Maintenancefirstsection from './Maintenancefirstsection'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';
const Maintenancewholepage = () => {
  return (
    <>
      <Helmet>
        <title>Reliable Software Maintenance & Support by Praathee Technologies</title>
        <meta name="description" content="Boost software performance with our expert maintenance and support. Timely updates and proactive monitoring for efficient systems." />
        <meta property="og:title" content="Trust Praathee Technologies for Expert Software Support & Maintenance" />
        <meta property="og:description" content="Ensure your software runs smoothly with our expert maintenance and support services. Trust us to optimize your software for peak efficiency." />
        <meta name="keywords" content="software maintenance, support , service , best, top, company" />
        <link rel="canonical" href="https://www.praathee.com/software-maintenance-service" />
      </Helmet>
      <Topnavbar />
      <Maintenancetopbanner />
      <Container>
        <div className='my-5'>
          <Maintenancefirstsection />
        </div>
        <div className='mb-5'>
          <Maintenanceimagesection />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Maintenancewholepage


