import React from 'react';
import circle1 from "../../src/assets/img/New_home_img/Delivery Time.png";
import circle2 from "../../src/assets/img/New_home_img/Good Quality.png";
import circle3 from "../../src/assets/img/New_home_img/Project Manager.png";

const Aboutcompany = () => {
  return (

    <>
    <div className='count-box'>
       
    </div>
    <div className="aboutcompany-container">
    <div className="circle-box">
      <div className="circle">
        <img src={circle3} alt="Delivery Time" />
      </div>
     <div className='company-count'>7+ <br/>years of <br/>establishment</div> 
    </div>
    <div className="circle-box">
      <div className="circle">
        <img src={circle1} alt="Good Quality" />
      </div>
   <div className='company-count'> 24/7 <br/>Support</div>  
    </div>
    <div className="circle-box">
      <div className="circle">
        <img src={circle2} alt="Project Manager" />
      </div>
    <div className='company-count'>100+<br/> Successful Projects</div>  
    </div>
  </div>
  </>
  );
};

export default Aboutcompany;
