import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Homepage from "./Home/Homepage";
import Aboutwholepage from "./About/Aboutwholepage";
import Serviceswholepage from "./Services/Serviceswholepage";
import Casestudywholepage from "./Casestudy/Casestudywholepage";
import Newsfeedwholepage from "./News/Newsfeedwholepage";
import ContactWholePage from "./Contact/ContactWholePage";
import Servicedetailwholepage from "./Services/ServiceDetail/Servicedetailwholepage";
import Projectdetails from "./Projectdetails/Projectdetails";
import Teamdetails from "./Teamdetails/Teamdetails";
import { ContactUs } from "./Contact/Careerpage";
import Newsdetailwholepage from "./News/Newsdetail/Newsdetailwholepage";
import Webwholepage from "./Services/Development/Web/Webwholepage";
import Mobilewholepage from "./Services/Development/Mobile/Mobilewholepage";
import Maintenancewholepage from "./Services/Development/Maintenance/Maintenancewholepage";
import Itstaffingwholepage from "./Services/Development/IT Staffing/Itstaffingwholepage";
import Enterprisewholepage from "./Services/Development/Enterprise/Enterprisewholepage";
import Customwholepage from "./Services/Development/Custom/Customwholepage";
import Cloudwholepage from "./Services/Development/Cloud/Cloudwholepage";
import Blockchainwholepage from "./Services/Development/Blockchain/Blockchainwholepage";
import Productswholepage from "./Products/Productswholepage";
import {Careerform} from "../src/Contact/Careerform";
import NotFound from "../src/common components/notFound";
import PrivacyPolicy from "./NewPages/PrivacyPolicy";
import TermsandConditions from "./NewPages/TermsandConditions";

const Routers = () => {
  


  return (
    <>
     
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<Aboutwholepage />} />
          <Route path="/services" element={<Serviceswholepage />} />
          <Route path="/Casestudy" element={<Casestudywholepage />} />
          <Route path="/News" element={<Newsfeedwholepage />} />
          <Route path="/contact" element={<ContactWholePage />} />
         
        

          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route path="/servicedetail" element={<Servicedetailwholepage />} />
          <Route path="/Projectdetail" element={<Projectdetails />} />
          <Route path="/Team" element={<Teamdetails />} />
          <Route path="/newsdetail" element={<Newsdetailwholepage/>}/>

          {/* Career Page */}
          <Route path="/careerpage" element={<ContactUs/>}/>
          <Route path="/web-app-development-service" element={<Webwholepage/>}/>
          <Route path="/mobile-app-development-service" element={<Mobilewholepage/>}/>
          <Route path="/software-maintenance-service" element={<Maintenancewholepage/>}/>
          <Route path="/it-staffing-service" element={<Itstaffingwholepage/>}/>
          <Route path="/erp-development" element={<Enterprisewholepage/>}/>
          <Route path="/customservice" element={<Customwholepage/>}/>
          <Route path="/cloud-app-development-service" element={<Cloudwholepage/>}/>
          <Route path="/blockchain-development-service" element={<Blockchainwholepage/>}/>

          <Route path="/prestidocs" element={<Productswholepage/>}/>
          <Route path="/careerform" element={<Careerform/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/terms-and-conditions" element={<TermsandConditions/>}/>
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};
export default Routers;