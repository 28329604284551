import React from 'react';
import ourcompanyimg from '../../src/assets/img/New_home_img/Rectangle 12.png';

const CompanyIntroduction = () => {
    return (
        <div  style={{ backgroundColor: "#008EFF",marginTop:'40px',marginBottom:'40px'}}>
            <div className="row">
                <div className="col-6">
                    <img src={ourcompanyimg} alt="Design experience" className="design-img" />
                </div>
                <div className="col-6" style={{ color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className='desgin-heading'>DESIGNING AN EXCEPTIONAL<br/> GLOBAL EXPERIENCE</div>
                    <div style={{textAlign:'justify',paddingRight:'107px'}}>
                        We are well versed in developing tailor-made applications using Microsoft technologies and open source. We understand that every business is unique and has its own set of challenges and goals.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompanyIntroduction;
