import React from 'react'
import Topnavbar from '../../../common components/Topnavbar'
import Cloudtopbanner from './Cloudtopbanner'
import Cloudfirstsection from './Cloudfirstsection'
import Cloudimagesection from './Cloudimagesection'
import Footer from '../../../common components/Footer'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet';

const Cloudwholepage = () => {
  return (
    <>
      <Helmet>
        <title>Leading Cloud App Development Service | Praathee Technologies</title>
        <meta name="description" content="Transform your business with innovative cloud app development services. Enhance flexibility, scalability, and efficiency with our expert team." />
        <meta property="og:title" content="Unleash the Power of Cloud Apps: Choose Praathee Technologies" />
        <meta property="og:description" content="Unlock the potential of cloud technology with Praathee Technologies. From concept to deployment, our expert team ensures seamless integration and maximum efficiency." />
        <meta name="keywords" content="cloud app development, company, service, cloud app, developer, cloud application development , saas app developers, saas app development, custom cloud application development" />
        <link rel="canonical" href="https://www.praathee.com/cloud-app-development-service" />
      </Helmet>
      <Topnavbar />
      <Cloudtopbanner />
      <Container>
        <div className='my-5'>
          <Cloudfirstsection />
        </div>
        <div className='mb-5'>
          <Cloudimagesection />
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Cloudwholepage
