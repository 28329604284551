

import React from 'react'
import soluptons from "../../../assets/img/techex-landing-page/we-provide-solutopns-bg.webp"


import { Row, Col } from 'react-bootstrap'

const Enterpriseimagesection = () => {
    return (
        <Row className="top-margin">
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(    ${soluptons})` }} alt="Enterprise software devleopment">
                    <div className="content common-card-images">
                        <h3>Tailored Solutions</h3>
                        <hr />
                        <p>We prioritize a personalized approach,Along with our team,we understand each client's unique requirements and business processes and make solution.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content card-image-mobile-service common-card-images">
                        <h3>Scalability</h3>
                        <hr />
                        <p>We incorporate new technologies to ensure that your app can scale effectively,Utilizing cloud-native architecture and microservices for enhanced flexibility and resilience.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>Security & Compliance</h3>
                        <hr />
                        <p>We tailor a unique set of security and compliance considerations for each project.Continuous monitoring to safeguard user data and uphold regulatory requirements.</p>
                    </div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>Dedicated Team</h3>
                        <hr />
                        <p>Our team consists of proficient and dedicated app developers who deliver high-end business solutions.Delivering top-tier solutions through app development.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>
                    <div className="content common-card-images">
                        <h3>Continuous Support</h3>
                        <hr />
                        <p>We provide post-launch support and maintenance, encompassing compliance, security tasks, and performance management for your business.</p>
                    </div>
                </a>
            </div>
            <div className="col-12 col-md-6 col-xl-4" data-aos="fade-up" data-aos-delay="100">
                <a className="single-provide-solutions" style={{ backgroundImage: `url(${soluptons})` }}>

                    <div className="content common-card-images">
                        <h3>Cost-effectiveness</h3>
                        <hr />
                        <p>Our competitive rates combined with exceptional quality ensure our mutual success.we strive to cultivate long-lasting partnerships built on trust and  growth.</p>
                    </div>
                </a>
            </div>
        </Row>
    )
}

export default Enterpriseimagesection