import React, { useState, useEffect } from 'react';
import firstimage from "../assets/img/New_home_img/1.jpeg"; // Ensure the path is correct

function Homepagetopbanner() {
  const texts = [
    "Mobile Apps",
    "Web Apps",
    "ERP",
    "Blockchain",
    "SEO"
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [texts.length]);

  const containerStyle = {
    display: 'flex',
    flexDirection: 'row', // Arrange content side by side
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
    width: '100vw',
    backgroundColor: 'white',
    position: 'relative', // To position the blobs and stars correctly
    overflow: 'hidden', // Ensure overflow is hidden
  };

  const imageStyle = {
    width: '50%', // Take up half of the width
    height: '100%',
    backgroundImage: `url(${firstimage})`,
    backgroundSize: 'contain', // Contain the image within the container
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
  };

  const contentStyle = {
    width: '50%', // Take up the other half of the width
    textAlign: 'center',
    padding: '20px',
    animation: 'fadeIn 2s ease-in-out',
    fontFamily: "Poppins",
  };

  const titleStyle = {
    fontSize: '44px', // Larger font for the main title
    fontWeight: 'bold',
    marginBottom: '20px',
    fontFamily: '"Open Sans", Roboto, sans-serif',
    background: 'linear-gradient(45deg, #21367F, #0298CA)',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    textAlign: 'left', // Align text to the left
    marginLeft: '0', // Optional: Adjust the left margin if needed
  };

  const rotatingTextStyle = {
    fontSize: '44px', // Medium font for the rotating text
    color: 'black', // Add a color to differentiate it
    marginTop: '-20px',
    animation: 'fadeIn 1s ease-in-out',
    fontFamily: '"Open Sans", Roboto, sans-serif',
    textAlign: 'left', // Align text to the left
    marginLeft: '0',
    fontWeight: '600'
  };

  const descriptionStyle = {
    fontSize: '1.2rem', // Smaller font for the description text
    marginTop: '15px',
    color: '#555',
    textAlign: 'left', // Align text to the left
    marginLeft: '0',
  };

  const blobStyle = {
    width: '80px',
    height: '80px',
    backgroundColor: 'red',
    position: 'absolute',
    left: '10px',
    top: '10px',
    backgroundImage: 'linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)',
    borderRadius: '70% 30% 30% 70% / 60% 40% 60% 40%',
    animation: 'mymove 3s alternate infinite ease-out',
  };

  const blob2Style = {
    width: '60px',
    height: '60px',
    position: 'absolute',
    right: '10px',
    bottom: '10px',
    backgroundImage: 'linear-gradient(19deg, #00c6ff 0%, #0072ff 100%)', // Updated linear gradient to blue
    borderRadius: '50%',
    animation: 'mymove 3s alternate infinite ease-out',
  };

  // Sparkling stars style
  const starStyle = {
    position: 'absolute',
    width: '20px', // Size of the star
    height: '20px',
    backgroundColor: 'purple', // Color of the star
    clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
    animation: 'sparkle 2s infinite ease-in-out',
  };
  
  

  const keyframesStyle = `
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @keyframes mymove {
      from {
        transform: translateY(0); /* Start position for vertical movement */
      }
      to {
        transform: translateY(30px); /* End position for vertical movement */
      }
    }

    @keyframes sparkle {
      0%, 100% {
        transform: scale(0);
        opacity: 0;
      }
      50% {
        transform: scale(1);
        opacity: 1;
      }
    }
  `;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 2; i++) {
      const top = `${Math.random() * 100}%`;
      const left = `${Math.random() * 10}%`;
      const delay = `${Math.random() * 5}s`;

      stars.push(
        <div
          key={i}
          style={{
            ...starStyle,
            top,
            left,
            animationDelay: delay,
          }}
        />
      );
    }
    return stars;
  };

  return (
    <>
      <style>{keyframesStyle}</style>
      <div style={containerStyle} className='container'>
        {renderStars()}
        <div style={blobStyle}></div>
        <div style={blob2Style}></div>
        <div style={imageStyle}></div>
        <div style={contentStyle}>
          <div style={titleStyle}>
            Empower Your Business Online with
            <span style={rotatingTextStyle}>  {texts[currentIndex]}</span>
          </div>
          <div>
          </div>
          <div style={descriptionStyle}>
            "Unlock your business's full potential. Contact us today—our experts are just a call or email away!"
          </div>
          <div className="flip-button-container">
            <div className="flip-button">
              <span className="flip-button-front">Explore</span>
              <span className="flip-button-back">Discover</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homepagetopbanner;
